<template>
  <div>
    <v-hover v-slot:default="{ hover }" v-if="!editing">
      <v-row>
        <v-col
          @click="onTextClick"
          xs12
          :class="{
            'grey lighten-3': hover,
            'px-0': noPadX,
            'py-0': noPadY
            }"
        >
          <p :class="classApplied">{{inputValue}}</p>
        </v-col>
      </v-row>
    </v-hover>
    <span v-else v-click-outside="close">
      <v-text-field
        @input="handleInput"
        hide-details
        ref="input_field"
        dense
        v-model="inputValue"
        :class="classApplied"
      ></v-text-field>
    </span>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  props: {
    value: {
      default: '',
      required: true
    },
    classApplied: {
      default: ''
    },
    noPadX: {
      default: false
    },
    noPadY: {
      default: false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      editing: false,
      inputValue: this.value
    }
  },
  methods: {
    close () {
      this.editing = false
      this.$emit('blur', this.inputValue)
    },
    handleInput () {
      this.$emit('input', this.inputValue)
    },
    onTextClick () {
      let self = this
      self.editing = true
      this.$nextTick(() => {
        console.log(self.$refs)
        self.$refs.input_field.$refs.input.focus()
      })
    }
  }
}
</script>
